import React, { useEffect, useState } from "react"

import "./Main.scss"
import { Link, useSearchParams } from "react-router-dom"
import { getFromLocalStorage, routes } from "../../utils"
import { useMedia } from "use-media"
import {preloadDataBsc} from "../../pages/SwapInfo/preloadDataBsc";
import {preloadData} from "../../pages/SwapInfo/preloadData";


export const Main = (): JSX.Element => {
  const [search] = useSearchParams()
  const isM = useMedia({ maxWidth: 1200 })
  const [ourTvlData, setOurTvlData] = useState<any>([])

  useEffect(() => {
    if (localStorage.getItem("swapData") !== null && localStorage.getItem("swapDataBsc") !== null) {
      return setOurTvlData(getFromLocalStorage("swapData").concat(getFromLocalStorage("swapDataBsc")))
    }
    if (localStorage.getItem("swapDataBsc") !== null) {
      return setOurTvlData(getFromLocalStorage("swapDataBsc").concat(preloadData))
    }
    if (localStorage.getItem("swapData") !== null) {
      return setOurTvlData(getFromLocalStorage("swapData").concat(preloadDataBsc))
    }
    setOurTvlData(preloadDataBsc.concat(preloadData))
  }, [])

  return (
    <main className="main">
      <h1 className="main-left-title">
        Experience <span>Trend Staking</span>, <br/>
        your gateway to unbounded<br/>
        earning in staking
      </h1>

      <div className="main-left-buttons">
        <Link to={`${routes.farms}?${search.toString()}`} className="main-left-buttons-item">
          Stake NOW
        </Link>
        {/*{isM ? (*/}
        {/*  <a download href={srcPath} className="main-left-buttons-item right">*/}
        {/*    Litepaper*/}
        {/*  </a>*/}
        {/*) : (*/}
        {/*  <Link to={`${routes.docs}?${search.toString()}`} target="_blank" className="main-left-buttons-item right">*/}
        {/*    Litepaper*/}
        {/*  </Link>*/}
        {/*)}*/}
      </div>
    </main>
  )
}
