export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/Trend_Protocol",
  mail: "mailto:support@trend.finance",
  discord: "",
  scan: "",
  telegram: "https://t.me/Trend_Finance",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  // audit: "https://lounge.audits-scrutify.io/",
};
