import React, {useState} from "react"

import { Medias, routes } from "utils"

import imgLogo from "assets/icons/TREND.svg"
import "./Footer.scss"
import { Link, useSearchParams } from "react-router-dom"
import {ConnectKitButton} from "connectkit";

export const Footer = (): JSX.Element => {
  const [search] = useSearchParams()

  return (
    <footer className="footer">
      <div className="footer-top">
        <img src={imgLogo} alt='logo' className='footer-left-logo'/>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </div>
      <div className="footer-med">
        <div className="footer-med-links">
          <Link className="footer-med-item" onClick={() => {
            window.scrollTo({
              top: 0,
            })
          }} to={`${routes.privacy}?${search.toString()}`}>
            Privacy Policy
          </Link>
          <Link className="footer-med-item" onClick={() => {
            window.scrollTo({
              top: 0,
            })
          }} to={`${routes.terms}?${search.toString()}`}>
            Terms & Conditions
          </Link>
          <Link className="footer-med-item" onClick={() => {
            window.scrollTo({
              top: 0,
            })
          }} to={`${routes.cookies}?${search.toString()}`}>
            Cookie Policy
          </Link>
        </div>
        <Medias/>
      </div>
      <div className='footer-bottom'>
        <p className="footer-reserved">Copyright © 2024 Trend Finance. All rights reserved</p>
        <address>
          1234 Wilshire Boulevard, Suite 567, Los Angeles, CA 90017 USA
        </address>

      </div>
    </footer>
  )
}
