import React from "react"

import imgBg from "assets/images/bg-partners.png"
import img1 from "assets/icons/partners/1.svg"
import img2 from "assets/icons/partners/2.svg"
import img3 from "assets/icons/partners/3.svg"
import img4 from "assets/icons/partners/4.svg"
import img5 from "assets/icons/partners/5.svg"
import img6 from "assets/icons/partners/6.svg"
import img9 from "assets/icons/partners/9.svg"
import "./Partners.scss"
import {InfinityScroll} from "../InfinityScroll";

export const Partners = (): JSX.Element => {
  const partners = [
    {
      link: img1,
      width: 241,
      height: 60,
    },
    {
      link: img2,
      width: 215,
      height: 60,

    },
    {
      link: img3,
      width: 280,
      height: 60,

    },
    {
      link: img4,
      width: 170,
      height: 60,

    },
    {
      link: img5,
      width: 200,
      height: 60,

    },
    {
      link: img6,
      width: 187,
      height: 60,

    },
    {
      link: img9,
      width: 220,
      height: 60,
    },
  ]
  return (
    <div className="partners">
      <div className='partners-header' style={{backgroundImage: `url(${imgBg})`}}>
        <h3 className="partners-title">Working together</h3>
        <p className="partners-desc">
          Partners from various industries trust us and help us to create a reliable ecosystem that benefits both individuals and businesses
        </p>

      </div>
      <div data-aos="fade-up-left" className="partners-content">
        <InfinityScroll items={partners}/>
      </div>
    </div>
  )
}
