import img1 from "assets/icons/pool/eth.svg";
import img2 from "assets/icons/pool/tether.svg";
import img3 from "assets/icons/pool/usdc.svg";
import img5 from "assets/icons/pool/uni.svg";
import img6 from "assets/icons/pool/link.svg";
import img7 from "assets/icons/pool/wbtc.svg";
import img8 from "assets/icons/pool/ape.svg";

export const poolDataSeparated = [
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0xab6b4b11378a57933333e4acfdc45567dd78f14e",
    day: "14",
    index: 1,
    quantity: "0.1-20",
    percent: "3.3",
    total: 1154.42288932,
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x80fbb6122b8e023988e640db1ae348a10a7933e8",
    day: "30",
    index: 2,
    quantity: "15-50",
    percent: "5.5",
    total: 1198.94382394,
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789",
    day: "60",
    index: 3,
    quantity: "30-100",
    percent: "9",
    total: 567.1437823,
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x49CaEB8175DC9Db709d4e6299CF8252ddDEE2738",
    day: "90",
    index: 4,
    quantity: "70-1000",
    percent: "13.8",
    total: 1490.7523255,
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    id: "tether",
    network: "Ethereum",
    address: "0x051f1d88f0af5763fb888ec4378b4d8b29ea3319",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "14",
    index: 1,
    percent: "0.7",
    quantity: "500-10000",
    total: 2392901.32789823,
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0x290d7c4a8b0343605330fb1f7bab174655c4c1b7",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "30",
    index: 2,
    percent: "1.6",
    quantity: "5000-50000",
    total: 4531423.2388933,
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc0947c152cd08f423b34a890126074d2648941ab",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "60",
    index: 3,
    percent: "4",
    quantity: "20000-100000",
    total: 4040862.3282392,
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc2e246f77be48c18c013ce5ab99346eec15dfd74",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "90",
    index: 4,
    percent: "6.5",
    quantity: "50000-1000000",
    total: 2881099.5230923,
  },
  {
    icon: img3,
    name: "USD Coin",
    id: "usd-coin",
    token: "USDC",
    network: "Ethereum",
    address: "0xe081d9d8fd994f0a17cd1edc4e6941a0333f686d",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "14",
    index: 1,
    percent: "1.2",
    total: 1670031.4389289,
    quantity: "3500-25000",
  },
  {
    icon: img3,
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0x124cc44b7119fb592a774f466823f31885b60440",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "30",
    index: 2,
    percent: "2.5",
    quantity: "15000-50000",
    total: 2423034.239885,
  },
  {
    icon: img3,
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0xbd55337583699e5f4849e3412ca2e05c39940a01",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "60",
    index: 3,
    percent: "6",
    quantity: "30000-100000",
    total: 3984200.676998,
  },
  {
    icon: img3,
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0xb8f1628f0945b215bd5202972246dcd185b2e958",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "90",
    index: 4,
    percent: "8",
    quantity: "70000-1000000",
    total: 4822973.3224323,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x0ef6aeb825dc4c9983d551f8afefaae9d79165c6",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "14",
    index: 1,
    percent: "0.4",
    quantity: "0.1-0.5",
    total: 44.27647,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0xefa6dbc560308338867ab6fa69a8c8f6bf14167e",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "30",
    index: 2,
    percent: "1",
    quantity: "0.3-1",
    total: 48.4266774,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1cb17a66dc606a52785f69f08f4256526abd4943",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "60",
    index: 3,
    percent: "2.5",
    quantity: "0.5-5",
    total: 53.473895,
  },
  {
    icon: img7,
    network: "Ethereum",
    name: "Wrapped Bitcoin",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1e227979f0b5bc691a70deaed2e0f39a6f538fd5",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "90",
    index: 4,
    percent: "4",
    quantity: "0.8-100",
    total: 58.2389582,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x8d9dcab8c99930d3476a99b19943901f3c32e5fb",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "14",
    index: 1,
    percent: "1.5",
    quantity: "500-2000",
    total: 46833.06442252,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0xf44c1b125e71c4b351ee2bef0e35d25ec76b08fc",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "30",
    index: 2,
    percent: "3.5",
    quantity: "1500-5000",
    total: 144429.3874,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x4889de78304826faf170642322723960637a28a6",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "60",
    index: 3,
    percent: "9",
    quantity: "3000-10000",
    total: 208327.3872,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x8ab96777100aa0735509d8921e3e852bbb3f53a0",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "90",
    index: 4,
    percent: "14",
    quantity: "7000-100000",
    total: 109323.2333,
  },
  {
    icon: img5,
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0x277c31565077d0fbd999e905466ee7289553c821",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "14",
    index: 1,
    percent: "2",
    quantity: "100-500",
    total: 29264.65,
  },
  {
    icon: img5,
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0xd11aae487b1eaccdbb9fb021c0e84b4f030c0d9c",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "30",
    index: 2,
    percent: "5",
    quantity: "300-2500",
    total: 20840.329,
  },
  {
    icon: img5,
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0x558ccfbb664c5e6cc624497a4aeccdcf2a565b68",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "60",
    index: 3,
    percent: "11",
    quantity: "500-10000",
    total: 24839.23834,
  },
  {
    icon: img5,
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0xb1102d897efeba4778fa7957619a5977efd0fb3d",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "90",
    index: 4,
    percent: "17",
    quantity: "1200-50000",
    total: 37483.937634,
  },
  {
    icon: img8,
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xbb0780090b4f69e5bbab9065104bffab357dfc55",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "14",
    index: 1,
    percent: "2",
    quantity: "2000-5000",
    total: 189382.8,
  },
  {
    icon: img8,
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xc79707530a2aeb59a587780241ca74d128642fdd",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "30",
    index: 2,
    percent: "5.5",
    quantity: "3000-8000",
    total: 167389.2398,
  },
  {
    icon: img8,
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0x2fee4d3d38e59774a4155a7d5f089308dbeb63ae",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "60",
    index: 3,
    percent: "12",
    quantity: "5000-12000",
    total: 93336.97634,
  },
  {
    icon: img8,
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0x7303faf749ba82f71c998ca6e27fe3f40c006718",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "90",
    index: 4,
    percent: "18",
    quantity: "8000-30000",
    total: 179268.342,
  },
  {
    icon: img8,
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xfaf5137f19f0f30aece41e08f77ea274490f0386",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "365",
    index: 5,
    percent: "90",
    quantity: "15000-50000",
    total: 138793.9832,
  },
];
