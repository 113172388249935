import React, {useEffect, useState} from "react"

import "./Leverage.scss"
import {LeverageItem} from "./LeverageItem";
import {getFromLocalStorage, useScrollPosition} from "../../utils";
import {preloadData} from "../../pages/SwapInfo/preloadData";

export const Leverage = (): JSX.Element => {
  const scroll = useScrollPosition()
  const [ourTvlData, setOurTvlData] = useState<any[]>([])

  const blocks = [
    {
      title: 1000000,
      desc: 'Profit Reward',
    },
    {
      title: 34000000,
      desc: 'Total value locked',
    },
    {
      title: 12000,
      desc: 'Total users',
    },
    {
      title: 10,
      desc: 'Available networks',
    },
  ]

  useEffect(() => {
    if (localStorage.getItem("swapData") !== null) {
      return setOurTvlData(getFromLocalStorage("swapData"))
    }

    setOurTvlData(preloadData)
  }, [])


  return (
    <div className="leverage">
      <h2 className="leverage-title">Empower your investments, real time staking insights at your fingertips</h2>
      <div className="leverage-content">
        {blocks.map((block, index) => (
          <LeverageItem scrollPosition={scroll >= 0} title={block.title} desc={block.desc} key={index}/>
        ))}
      </div>
    </div>
  )
}
