import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from '@sooro-io/react-gtm-module'
// @ts-ignore
import { WagmiProvider, createConfig, http } from "wagmi";
// @ts-ignore
import { mainnet, bsc, arbitrum } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// @ts-ignore
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import App from "./App";

TagManager.initialize({gtmId: 'G-4WSE2CV3J8'})

export const config = createConfig(
  // @ts-ignore
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet, bsc, arbitrum],
    transports: {
      // RPC URL for each chain
      [mainnet.id]: http(
        `https://eth-mainnet.g.alchemy.com/v2/adUCkQLHeotIto_CrVwkvMIxM7o5RE4E`,
      ),
      // RPC URL for each chain
      [bsc.id]: http(
        `https://bsc-dataseed.binance.org/`
      ),
      // RPC URL for each chain
      [arbitrum.id]: http(
        `https://arb1.arbitrum.io/rpc`
      ),
    },
    // Required API Keys
    walletConnectProjectId: '',
  }),
);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiProvider  config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider customTheme={{
          "--ck-connectbutton-font-size": "15px",
          "--ck-font-family": "'Proxima Nova' ,sans-serif",
          "--ck-connectbutton-font-weight": 600,
          "--ck-connectbutton-background": '#dce3ed',
          "--ck-connectbutton-hover-background": 'initial',
          "--ck-connectbutton-hover-box-shadow": 'none',
          "--ck-connectbutton-box-shadow":'none',
          "--ck-connectbutton-color":'#0a0d12',

        }}>
          <App />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
