import React from "react"

import img1Src from "assets/images/swap.png"
import img2Src from "assets/images/secure.svg"
import "./Secure.scss"


export const Secure = (): JSX.Element => {
  return (
    <div className="secure">
      <div className='secure-first' data-aos="fade-up-right">
        <div className='secure-first-text'>
          <h3>Apply algorithms<br/>for best Rewards</h3>
          <p>Trend Finance utilizes sophisticated algorithms to maximize staking rewards by analyzing market conditions and optimizing staking strategies in real-time</p>
        </div>
        <img src={img1Src} alt="main left" className="secure-first-img" />
      </div>
      <div className='secure-second' data-aos="fade-up-left">
        <img src={img2Src} alt="main left" className="secure-second-img"/>
        <div className='secure-second-text'>
          <h3>Secure</h3>
          <p>
            Security is paramount for DeFi and embedded in Trend Finance’s DNA. Securely execute interchain strategies
            with noncustodial vaults - your keys, your crypto. Trend Finance’s software is audited by InterFi Network.
          </p>
        </div>
      </div>
    </div>
  )
}
