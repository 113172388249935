import React, { useEffect, useState } from "react"
import { Header, Footer, Table } from "components"
import {AnnouncementModal, CancelModal, ConnectModal} from "../../modals"

import "./Farms.scss"
import cn from "classnames"
import {TransferModal} from "../../modals/TransferModal";
import {useAccount} from "wagmi";

const tabs = ["All Pools", "Staked Pools"]

export const Farms = (): JSX.Element => {
  const [tab, setTab] = useState("All Pools")
  const [cancel, setCancel] = useState(false)
  const [transferModal, setTransferModal] = useState<any | undefined>(undefined)
  const [claimAnn, setClaimAnn] = useState(false)
  const [connectModal, setConnectModal] = useState(false)
  const { address } = useAccount();
  const body = document.body

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  useEffect(() => {
    if (!address) {
      setConnectModal(true)
    } else {
      setConnectModal(false)
      if (body) {
        body.style.overflow = "scroll"
        body.style.overflow = "scroll"
      }
    }
  }, [address])

  return (
    <div className="farms">
      <Header />
      <div className="farms-content">
        <div className="farms-content-header">
          <h3 className="farms-content-header-title">Staking Pools</h3>
        </div>

          <Table openClaimAnn={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setClaimAnn(true)
          }} openCancel={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setCancel(true)
          }} stakedDisplay={tab === "Staked Pools"} openTransfer={(current, money, interests, length, allRecords) => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setTransferModal({
              plan: current,
              money,
              interests,
              totalLength: length,
              allRecords,
            })
          }}/>
      </div>
      {cancel && <CancelModal onClose={() => setCancel(false)}/>}
      {claimAnn && <AnnouncementModal
          text='Due to current high levels of congestion on the Ethereum network, transaction times may be longer than usual. All transactions should be confirmed within the next 12 hours.'
          onClose={() => setClaimAnn(false)}/>}
      {!!transferModal && <TransferModal onClose={() => {
        setTransferModal(undefined)
        location.reload()
      }}
                                         allRecords={transferModal.allRecords}
                                         currentPlan={transferModal.plan} totalLength={transferModal.totalLength} currentMoney={transferModal.money}
                                         interests={transferModal.interests}/>}
      {/*{connectModal && <ConnectModal onClose={() => setConnectModal(false)} />}*/}
      <Footer />
    </div>
  )
}
