import React, { useEffect, useState } from "react"
import {
  Header,
  Footer,
  Main,
  Features,
  Ecosystem,
  Loading,
  Partners,
  Investors,
  Leverage,
  Secure,
  NewsHome, Chains
} from "components"

import "./Home.scss"


export const Home = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <div className="home">
      {!loaded && <Loading />}
      <Header />
      <Main />
      <Leverage />
      <Features />
      <Partners />
      <Investors />
      <Ecosystem />
      <Secure />
      <Chains />
      {/*<NewsHome />*/}
      <Footer />
    </div>
  )
}
